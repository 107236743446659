// Libs
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from 'components/device';
import SEO from 'components/seo';

// Assets
import LogoSvg from 'images/logos/logo_hsl.svg';

// Styles
import 'components/layout.css';
const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  height: 100vh;
  padding: 1.875rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    height: unset;
    padding: 1.25rem;
  }
`;

const LogoHSLContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.875rem;
  left: 0;

  @media ${device.laptop} {
    top: 1.25rem;
    left: 1.25rem;
  }
`;

const LogoHSL = styled.img`
  width: 12.5rem;
  height: 3rem;
  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    width: 10.625rem;
    height: 2.563rem;
  }
`;

const Content = styled.section`
  display: flex;
  align-items: center;
  gap: 8.125rem;
  margin-top: 6rem;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 4rem;
  }
`;

const Image = styled.img`
  width: 25rem;
  height: 25rem;

  object-fit: contain;
  object-position: center;

  @media ${device.laptop} {
    width: 18.625rem;
    height: 18.625rem;
  }
`;

const Information = styled(ReactMarkdown)`
  padding-right: 4rem;

  h4 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #231f20;
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
    color: #231f20;
  }

  p + p {
    margin-top: 1.25rem;
  }

  a {
    font-size: 1rem;
    line-height: 1.375rem;
    color: #45a7df;
  }

  p:nth-child(5),
  p:nth-child(6) {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    em {
      color: #8f9194;
      width: calc(100% - 2.25rem);
      display: inline-block;
      margin-left: 2.25rem;
      font-style: normal;

      strong {
        color: #231f20;
      }
    }
  }

  @media ${device.laptop} {
    padding-right: 0;

    h4 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: 1.25rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.375;
    }

    a {
      font-size: 0.875rem;
      line-height: 1.375;
    }
  }
`;

const Maintenance = ({ page }) => {
  function LinkRenderer({ href, children }) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <>
      <SEO dataSeo={page} />
      <Container>
        <LogoHSLContainer>
          <LogoHSL src={LogoSvg} alt="Hospital Sírio Libanês" />
        </LogoHSLContainer>
        <Content>
          <Image
            src={
              page?.[0]?.fragments?.[0]?.assetpicker?.url ??
              `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/fJUaquexSPWtjj2EGBCJ`
            }
            alt="Informações"
          />
          <Information renderers={{ link: LinkRenderer }}>
            {page?.[0]?.fragments?.[0]?.localizations?.[0]?.markdown ??
              `#### Estamos em manutenção\n\nSentimos muito pelo transtorno. \n\nNosso site está indisponível no momento, mas você pode acessar o [Portal do Paciente](https://paciente.hsl.org.br/) ou [Portal do Médico](https://portalmedico.hsl.org.br/conta/login).\n\nSe precisar falar com a gente, entre em contato com a nossa Central de Informações:\n\n![telefone](${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/NA8zgMjvQ2Og6wHIbdPp)*São Paulo*\n*[+55 (11) 3394-0200](tel:+55-11-3394-0200)*\n\n![telefone](${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/NA8zgMjvQ2Og6wHIbdPp)*Brasília*\n*[+55 (61) 3044-8888 (opção 4)](tel:+55-61-3044-8888)*\n`}
          </Information>
        </Content>
      </Container>
    </>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cl0jw0fgw7y9r0eljxaqgtemm" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Maintenance page={response.gcms.site.pages} />;
      }}
    />
  );
};
